import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import AliceCarousel from "react-alice-carousel";
import { Link } from "react-router-dom";

let API_KEY = "AIzaSyD1h6TYaAtmlO8nwV5JzBsqeAC0abzTsc8";

let endpoint = `https://youtube.googleapis.com/youtube/v3/channels?part=snippet%2CcontentDetails%2Cstatistics&id=UCBJycsmduvYEL83R_U4JriQ&key=${API_KEY}`

const makeEndPoint = (id) => {
    return `https://youtube.googleapis.com/youtube/v3/channels?part=snippet%2CcontentDetails%2Cstatistics&id=${id}&key=${API_KEY}`
}

let userIds = [
  "UCOhHO2ICt0ti9KAh-QHvttQ",
  "UCsTcErHg8oDvUnTzoqsYeNw",
  "UCBJycsmduvYEL83R_U4JriQ",
  "UCXuqSBlHAE6Xw-yeJA0Tunw",
  "UCMiJRAwDNSNzuYeN2uWa0pA",
  "UC1tVU8H153ZFO9eRsxdJlhA",
  "UCXUJJNoP1QupwsYIWFXmsZg",
  "UCey_c7U86mJGz1VJWH5CYPA",
  "UCXGgrKt94gR6lmN4aN3mYTg",
  "UC9fSZHEh6XsRpX-xJc6lT3A",
];

function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

 const useStyles = makeStyles((theme) => ({
    carousel: {
      height: "50%",
      display: "flex",
      alignItems: "center",
    },
    carouselItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      textTransform: "uppercase",
      color: "black",
    },
  }));

const Carousel = () => {
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState([]);

  const fetchUserInfos = async () => {
    try {
      const requests = userIds.map((id) => axios.get(makeEndPoint(id)));
      const responses = await Promise.all(requests);
      const userData = responses.map((response) => response.data);
      setUserInfo(userData);
      console.log(userData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserInfos();
  },[])

  const items = userInfo.map((user) => {
    const userData = user.items[0]; // Access the first item in the array
    return (
      <Link
        className={classes.carouselItem}
        to={`/channel/${userData.id}`}
        key={userData.id} // Add a unique key for each item
      >
        <img
          src={userData.snippet?.thumbnails?.default?.url} // Update the property access
          alt="youtuber"
          height='80'
          style={{ marginBottom: 10 , borderRadius: 12,}}
        />
        <span>
            {userData?.snippet?.title}
            &nbsp;
        </span>
        <span style={{fontSize: 22, fontWeight: 500}}>
            {nFormatter(userData?.statistics?.subscriberCount,userData?.statistics?.subscriberCount.length)}
        </span>
      </Link>
    );
  });

  const responsive = {
    0: {
        items: 1,
    },
    256:{
      items:2,
    },
    512: {
        items: 3,
    },
    812: {
        items: 4,
    },
  }

  return <div className={classes.carousel}>
        <AliceCarousel
        mouseTracking
        infinite
        autoPlayInterval={1000}
        animationDuration={1700}
        disableButtonsControls
        disableDotsControls
        responsive={responsive}
        items={items}
        autoPlay
        />
  </div>;
};

export default Carousel;
