import React from 'react';
import { AppBar, Container, ThemeProvider, Toolbar, Typography, createTheme, IconButton, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { Timeline } from '@material-ui/lab';
import analyticsIcon from '../images/analytics2.jpg';

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
    color: "#FF4040",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    cursor: "pointer",
  },
  icon: {
    width: 40,
    height: 25,
    marginRight: 2,
    borderRadius: 4
  },
}));

const Header = () => {
  const darkTheme = createTheme({
    palette: {
      main: {
        color: '#fff',
      },
      type: 'dark',
    },
  });

  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const fontSize = isLargeScreen ? 'h6' : 'h8';

  return (
    <ThemeProvider>
      <AppBar color='transparent' position='static'>
        <Container>
          <Toolbar>
            <img src={analyticsIcon} alt="icon" className={classes.icon} />
            <Typography className={classes.title} variant={fontSize} style={{ margin: 8, letterSpacing: '0.2' }}>Tech Metrics</Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
