import {
  Container,
  LinearProgress,
  Paper,
  TableContainer,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#333",
    },
    type: "light",
  },
});

let API_KEY = "AIzaSyD1h6TYaAtmlO8nwV5JzBsqeAC0abzTsc8";

let endpoint = `https://youtube.googleapis.com/youtube/v3/channels?part=snippet%2CcontentDetails%2Cstatistics&id=UCBJycsmduvYEL83R_U4JriQ&key=${API_KEY}`;

const makeEndPoint = (id) => {
  return `https://youtube.googleapis.com/youtube/v3/channels?part=snippet%2CcontentDetails%2Cstatistics&id=${id}&key=${API_KEY}`;
};

let userIds = [
  "UCOhHO2ICt0ti9KAh-QHvttQ",
  "UCsTcErHg8oDvUnTzoqsYeNw",
  "UCBJycsmduvYEL83R_U4JriQ",
  "UCXuqSBlHAE6Xw-yeJA0Tunw",
  "UCMiJRAwDNSNzuYeN2uWa0pA",
  "UC1tVU8H153ZFO9eRsxdJlhA",
  "UCXUJJNoP1QupwsYIWFXmsZg",
  "UCey_c7U86mJGz1VJWH5CYPA",
  "UCXGgrKt94gR6lmN4aN3mYTg",
  "UC9fSZHEh6XsRpX-xJc6lT3A",
];

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol : "0";
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: "#fff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F9F6EE",
    },
    fontFamily: "Montserrat",
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      color: "white",
      fontWeight: "600",
      backgroundColor: "#FF4040"
    },
  },
}));

const UserTable = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [page, setPage] = useState(1);

  const fetchUserInfos = async () => {
    try {
      setLoading(true);
      const requests = userIds.map((id) => axios.get(makeEndPoint(id)));
      const responses = await Promise.all(requests);
      const userData = responses.map((response) => response.data);
      userData.sort((a, b) => {
        const subsA = parseInt(a.items[0].statistics.subscriberCount);
        const subsB = parseInt(b.items[0].statistics.subscriberCount);
        return subsB - subsA;
      });
      setUserInfo(userData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserInfos();
  }, []);

  const handleSearch = () => {
    let ls = search.toLowerCase();
    const filteredUsers = userInfo.filter((user) =>
      user?.items[0].snippet?.localized?.title.toLowerCase().includes(ls)
    );
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;
    return filteredUsers.slice(startIndex, endIndex);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container style={{ textAlign: "center" }}>
        <Typography 
          variant="h4"
          style={{ margin: 18, fontFamily: "Montserrat" }}
        >
          Tech Youtubers Data Analytics
        </Typography>
        <TextField
          variant="outlined"
          label="Search For a Tech Youtuber..."
          style={{ marginBottom: 20, width: "100%", color:'white' }}
          onChange={(e) => setSearch(e.target.value)}
        />
        <TableContainer component={Paper}>
          {loading ? (
            <LinearProgress style={{ backgroundColor: "red" }} />
          ) : (
            <Table aria-label="simple table">
              <TableHead style={{ backgroundColor: "#FF4040" }}>
                <TableRow>
                  {["Channel", "Subs", "Views", "Videos"].map((head) => (
                    <TableCell
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontFamily: "Montserrat",
                      }}
                      key={head}
                      align={head === "Channel" ? "" : "right"}
                    >
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                
                {handleSearch().map((row) => {
                  console.log(row);
                  console.log("roww")
                  return (
                    <TableRow className={classes.row} key={row.etag}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ display: "flex", gap: "15" }}
                      >
                        <img
                          src={row.items[0].snippet?.thumbnails?.default?.url} // Update the property access
                          alt="youtuber"
                          height='50'
                          style={{ marginBottom: 10 , borderRadius: 12,}}
                        />
                        <div
                        style={{ display: "flex", flexDirection: "column", alignItems: 'start', margin: 10}}
                        >
                         <span
                          style={{
                            textTransform: "uppercase",
                            fontSize: 12,
                          }}
                         >
                          {row?.items[0]?.snippet?.title}
                          </span>   
                          <span style={{ color: "darkgrey" }}>
                              {row.items[0].snippet?.country ? row.items[0].snippet?.country : "NA"}
                            </span>
                        </div>
                      </TableCell >
                      <TableCell align="right">
                        {nFormatter(row?.items[0]?.statistics?.subscriberCount,row?.items[0]?.statistics?.subscriberCount.length)}
                      </TableCell>
                      <TableCell
                          align="right"
                          style={{
                            color: "rgb(14, 203, 129)",
                            fontWeight: 500,
                          }}
                        >
                          {nFormatter(row?.items[0]?.statistics?.viewCount,row?.items[0]?.statistics?.viewCount.length)}
                        </TableCell>
                        <TableCell align="right">
                        {numberWithCommas(row?.items[0]?.statistics?.videoCount)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        <Pagination
        count={Math.ceil(handleSearch()?.length / 10) || 1}
        style={{
          padding: 20,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        classes={{ ul: classes.pagination }}
        onChange={(_, value) => {
          setPage(value);
          window.scroll(0, 450);
        }}
      />

      </Container>
    </ThemeProvider>
  );
};

export default UserTable;
