import React from 'react'
import Banner from '../Components/Banner/Banner'
import UserTable from '../Components/UserTable'

const Home = () => {
  return (
    <>
    <Banner/>
    <UserTable />
    </>
  )
}


export default Home